<template>
<div>

<div class="nav">
    <router-link to="/" class="navlink" style="color: #036D69"> Home </router-link>
</div>

<transition name="fade">
<div style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 100%; height: 450px; margin-top: 4%">
    <div style="width: 5%; height: 100%; display: flex; flex-direction: column; align-items: center;">
        <div class="toolButton" style="background-color: lightgreen" v-on:click="returnMain"> <span>M</span> </div>
        <div class="toolButton" v-if="detailDisplay" style="background-color: red" v-on:click="modalDeactivate = 1"> <span>X</span> </div>
        <!-- <div class="toolButton" style="background-color: #9CDDD9;" v-on:click="modalAddFacility = 1"> <span>F</span></div>
        <div class="toolButton" style="background-color: #8CB6D2; font-size: 2em" v-on:click="modalAddUser = 1"> <span>+</span> </div> -->
    </div>

<!----------------------------------------------- View All Facilities ------------------------------------------------------------->

    <div v-if="mainDisplay" style="width: 95%; height: 100%; display: flex; justify-content: center; align-items: center">
        <div class="cardBody">
              <div class="card" style="cursor: pointer; background-color: #8CB6D2" v-on:click="modalAddFacility = 1">
                  <h2 class="cardHeader" style="color: #FFF; font-size: 4em"> + </h2>
              </div>
              <div class="card" v-for="(facility, i) in facilities" v-bind:key="i" style="cursor: pointer;" v-on:click="showDetails(facility)">
                  <div style="background-color: #07ABA1; "> Facility </div>
                  <div style="margin: auto 0"> 
                      <h2 class="cardHeader">{{facility.name}}</h2>
                  </div>
              </div>
        </div>
    </div>

<!----------------------------------------------- View Facility------------------------------------------------------------->

    <div v-if="detailDisplay" style="width: 95%; height: 100%; display: flex; justify-content: center; align-items: center">
        <div class="cardBody">
              <div class="card" style="cursor: pointer; background-color: #FF9B9B" v-on:click="addUser(currentFacility.FID)">
                  <h2 class="cardHeader" style="color: #FFF; font-size: 4em"> + </h2>
              </div>
              <div class="card" v-for="(member, i) in currentFacility.members" v-bind:key="i" style="cursor: pointer;" v-on:click="showMemberDetails(member)">
                  <div style="background-color: #07ABA1; "> User </div>
                  <div style="margin: auto 0"> 
                      <h2 class="cardHeader">{{member}}</h2>
                  </div>
              </div>
        </div>
    </div>

<!----------------------------------------------- View Member ------------------------------------------------------------->

    <div v-if="memberDisplay" style="width: 95%; height: 100%; display: flex; justify-content: center; align-items: center">
        <div>
            <div style="display: flex; flex-direction: row; width: 700px; height: 400px; border: dotted 1px black">
                <div style="display: flex; flex-direction: column; align-items: start; justify-content: start; width: 35%;">
                    <div style="width: 150px; height: 150px; border-radius: 50%; background-color: lightgreen; margin-top: 5%; height: 40%; margin-left: 18%">
                    </div>
                    <div style="height: 35%; width: 100%">
                        <h3>{{targetUser.name}}</h3>
                    </div>
                    <div style="height: 30%; width: 100%">
                        <h1 style="margin: 0; padding: 0">{{targetUser.title}}</h1>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; height: 100%; width: 65%;">
                    <div style="height: 25%">
                        <h3 style="margin: 0; padding: 0; padding-top: 2%"> Additional Ordering Locations</h3>
                        <ul>
                          <li v-for="(facility, i) in targetUser.additional" :bind-key="i">{{facility}}</li>
                        </ul>
                    </div>
                    <div class="cardBodySmall" style="overflow-y: scroll;">
                      <div class="cardSmall" v-for="(facility, i) in facilities" :bind-key="i" style="background-color: lightgrey" v-if="facility.name !== targetUser.title">
                          {{facility.name}}
                          <div style="padding-top: 15%">
                           <input type="checkbox" :value="facility.name" v-model="additionalForm">
                           </div>
                      </div>
                    </div>
                </div>
            </div>

            <div style="padding-top: 4%">
            <button style="margin-right: 2%;" v-on:click="updateUser"> Update </button>
            <button style="background-color: #8CB6D2;" v-on:click="returnFacility"> Return </button>
            </div>
        </div>
    </div>

</div>
</transition>

<!----------------------------------------------- Add a Facility Modal ------------------------------------------------------------>

<transition name="modal">
<div v-if="modalAddFacility" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 450px; height: 200px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
            <h2 class="modal-banner" style="width: 100%; font-size: 1; font-size: 1.7em">Add Facility</h2>
        </slot>
      </div>

      <div class="modal-body">
        <slot name="body">
            <div class="form-style-7" style="margin: 0">
            <ul>
            <li>
                <label for="Name">Name</label>
                <input type="text" name="name" v-model.trim="facilityForm.name">
                <span>Enter a Name for the Facility <span style="font-weight: 700; color: darkgrey">(THIS NAME IS PERMANENT)</span></span>
            </li>
            <button class="modal-default-button" style="width: 65px"  v-on:click="createFacility"> Create </button>
            <button class="modal-default-button" style="width: 65px; background-color: #8CB6D2;" v-on:click="modalAddFacility = 0"> Cancel </button>
            </ul>
            </div>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

<!----------------------------------------------- Add a User Modal ------------------------------------------------------------>

<transition name="modal">
<div v-if="modalAddUser" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 450px; height: 500px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
            <h2 class="modal-banner" style="width: 100%; font-size: 1; font-size: 1.7em">Add User</h2>
        </slot>
      </div>

      <div class="modal-body">
        <slot name="body">
            <div class="form-style-7" style="margin: 0">
            <ul>
            <li>
                <label for="Name">Name</label>
                <input type="text" name="name" v-model.trim="signupForm.name">
                <span>Enter a Name i.e. 'John Smith'</span>
            </li>
            <li>
                <label for="Email">Email</label>
                <input type="text" name="email" v-model.trim="signupForm.email">
                <span>Enter an email address for login</span>
            </li>
           <li>
                <label for="Password">Password</label>
                <input type="password" name="password" v-model.trim="signupForm.password">
                <span>Input a password</span>
            </li> 
            <li v-if="!fromFacility">
                <label for="Facility">Facility</label>
                <h3 style="margin: 0; padding: 0">{{signupForm.title}}</h3>
                <span>
                <select name="facility" v-model.trim="signupForm.title">
                <option v-for="(facility, i) in facilities" v-bind:value="facility">
                  {{facility.name}}
                </option>
                </select>
                </span>
            </li>
            <li v-else>
                <label for="Facility">Facility </label>
                <h3 style="margin: 0; padding: 0"> {{currentFacility.name}} </h3>
            </li>
            <button class="modal-default-button" style="width: 65px;"  v-on:click="createUser"> Create </button>
            <button class="modal-default-button" style="width: 65px; background-color: #8CB6D2;" v-on:click="modalAddUser = 0"> Cancel </button>
            </ul>
            </div>
        </slot>
      </div>
      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

<!----------------------------------------------- Confirm Delete Modal ------------------------------------------------------------>

<transition name="modal">
<div v-if="modalDeactivate" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 450px; height: 400px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
            <h2 class="modal-banner" style="width: 100%; font-size: 1; font-size: 1.7em">Remove Facility</h2>
        </slot>
      </div>

      <div class="modal-body">
        <slot name="body">
          <div class="form-style-7">
            <h1> Are you sure you want to deactivate this facility? </h1>
            <p> {{targetFacility}} </p>
            <button class="modal-default-button" style="width: 65px;" v-on:click="removeFacility"> Yes </button>
            <button class="modal-default-button" style="width: 65px; background-color: #8CB6D2;" v-on:click="modalDeactivate = 0"> Cancel </button>
          </div>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>



</div>
</template>

<script>
export default {
    created() {
      this.getData()
      this.getUsers()
    },
    data() {
      return {
        signupForm: {
          name: '',
          title: '',
          email: '',
          password: '',
        },
        facilityForm: {
          name: '',
          date: '',
        },
        currentFacility: {
          name: '',
          members: [],
          FID: ''
        },
        modalAddFacility: 0,
        modalAddUser: 0,
        modalDeactivate: 0,
        mainDisplay: 1,
        detailDisplay: 0,
        memberDisplay: 0,
        facilities: [],
        fromFacility: 0,
        users: {},
        targetUser: {},
        targetFacility: {},
        additionalForm: []
      }
    },
    methods: {
      getData() {
        this.$store.dispatch('getFacilities').then(res => {
            console.log(res)
            this.facilities = res
        })
      },
      getUsers() {
        this.$store.dispatch('getUsers').then(res => {
            let output = {}
            for(let i = 0; i < res.length; i++){
              this.users[res[i].name] = res[i]
            }
        })
      },
      signup() {
        if(this.fromFacility){
          this.signupForm.title = this.currentFacility.name
        }
        this.$store.dispatch('signup', {
          email: this.signupForm.email,
          password: this.signupForm.password,
          name: this.signupForm.name,
          title: this.signupForm.title
        })

        /// then update the facility member's array
        this.$store.dispatch('addMember', {
          name: this.signupForm.name,
          FID: this.currentFacility.FID
        }).then(() => {
          setTimeout(() => {this.$router.go(0)}, 1000)
        })
      },
      createUser(){
        this.signup()
        this.modalAddUser = 0
      },
      addUser(){
        this.modalAddUser = 1
        this.fromFacility = 1
      },
      createFacility(){
        console.log("create a facility")
        console.log(this.facilityForm.name)

        this.facilityForm.date = new Date() 
        let output = this.facilityForm
        this.$store.dispatch('createFacility', output).then(res => {
          this.modalAddFacility = 0
          this.$store.dispatch('getFacilities').then(res => {
            console.log(res)
            this.facilities = res
          })
        })
      },
      showDetails(facility){
        this.mainDisplay = 0
        this.detailDisplay = 1
        console.log(facility)
        this.targetFacility = facility
        this.currentFacility.name = facility.name
        this.currentFacility.members = facility.members
        this.currentFacility.FID = facility.FID
      },
      showMemberDetails(member){
        this.mainDisplay = 0
        this.detailDisplay = 0
        this.memberDisplay = 1
        console.log(member)
        // find the user to grab credentials
        this.targetUser = this.users[member]
        for(let i = 0; i < this.targetUser.additional.length; i++){
          let cur = this.targetUser.additional[i]
        }
      },
      updateUser(){
        // console.log(this.additionalForm)
        // console.log(this.targetUser.FID)
        let output = {}
        output.FID = this.targetUser.FID
        output.additional = this.additionalForm
        output.additional.push(this.targetUser.title)
        // console.log(output)
        this.$store.dispatch('updateUser', output).then(res => {
          console.log(res)
          this.additionalForm = 0
          this.targetUser = {}
          setTimeout(() => {this.$router.go(0)}, 1000)
        })
      },
      returnMain(){
        this.mainDisplay = 1
        this.detailDisplay = 0
        this.memberDisplay = 0
      },
      returnFacility(){
        this.detailDisplay = 1
        this.memberDisplay = 0
      },
      removeFacility(){
        let output = this.targetFacility
        this.$store.dispatch('removeFacility', output).then(res => {
          console.log(res)
          setTimeout(() => {this.$router.go(0)}, 1000)
        })
      }
  }
}
</script>

<style scoped>
    table, th, td {
      border: 1px solid #DFDFDF;
      padding: 2px;
    }
    table {
        border-collapse: collapse;
        margin-left:auto;
        margin-right:auto;
    }  

    ul {
      list-style-type: square;
    }

    h3 {
      margin: 40px 0 0;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #07ABA1;
      font-size: 1.5em;
    }
    button {
      padding: 1%;
      font-size: 1em;
      background-color: #07ABA1;
      color: #FFF;
      border-radius: 2px;
      border: none;
      cursor: pointer;
      outline: none;
      box-shadow: none;
    }
    .toolButton {
      width: 50px; 
      height: 50px; 
      margin: 1%; 
      margin-top: 10%;
      cursor: pointer;
      border-radius: 50%;
      color: #FFF;
      font-size: 1.5em;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nav {
      padding-left: 3%;
      height: 1vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .navlink {
      padding-right: 25px;
      font-size: 1.5em;
      text-decoration: none;
    }
    .photo {
      width: 75px;
      height: 75px;
      object-fit: cover;
    }

    .test {
      margin: 0px;
      padding: 0px;
    }

    h1, h2 {
      font-weight: normal;
    }
    .cardBody {
      display: grid;
      grid-template-columns: 250px 250px 250px 250px;
      grid-gap: 10px;
      padding: 1%;
      overflow-y: scroll;
      height: 400px;
    }
    .cardBodySmall {
      display: grid;
      grid-template-columns: 75px 75px 75px 75px 75px;
      grid-gap: 10px;
      overflow-y: scroll;
    }
    .cardSmall {
      border-radius: 5px;
      width: 75px;
      height: 75px;
      display: flex;
      flex-direction: column;
    }
    .cardHeader {
      font-size: 1.7em;
      margin: auto 0
    }
    .card {
      background-color: lightgrey;
      border-radius: 5px;
      width: 250px;
      height: 200px;
      display: flex;
      flex-direction: column;
    }

/* Modal CSS */

    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    .modal-container {
      width: 1000px;
      height: 500px;
      margin: 0px auto;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      overflow-y: scroll;
      padding-bottom: 2%;
    }

    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }

    .modal-body {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .modal-default-button {
      margin: 5px;
    }
    .modal-banner {
      margin: 0px;
      padding: 0px; 
      background-color: #07ABA1; 
      width: 98%;
      font-size: 2em;
      color: #FFF;

    }
    .cancelButton {
      background-color: #07ABA1;
      color: #FFF; 
      float: left; 
      padding-right: 2%;
      font-size: 1.4em;
      font-weight: 1200;
      border-radius: 0px;
    }

    /* Transition CSS */

    .fade-enter-active, .fade-leave-active {
      transition-property: opacity;
      transition-duration: .25s;
    }

    .fade-enter-active {
      transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
      opacity: 0;
    }

    .modal-enter {
      opacity: 0;
    }       

    .modal-leave-active {
      opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
/*                form test                       */
    .form-style-7{
      max-width:400px;
      margin:50px auto;
      background:#fff;
      border-radius:2px;
      padding:20px;
    }
    .form-style-7 h1{
      display: block;
      text-align: center;
      padding: 0;
      margin: 0px 0px 20px 0px;
      color: #5C5C5C;
      font-size:x-large;
    }
    .form-style-7 ul{
      list-style:none;
      padding:0;
      margin:0;	
    }
    .form-style-7 li{
      display: block;
      padding: 9px;
      border:1px solid #DDDDDD;
      margin-bottom: 30px;
      border-radius: 3px;
    }
    .form-style-7 li:last-child{
      border:none;
      margin-bottom: 0px;
      text-align: center;
    }
    .form-style-7 li > label{
      display: block;
      float: left;
      margin-top: -19px;
      background: #FFFFFF;
      height: 14px;
      padding: 2px 5px 2px 5px;
      color: #B9B9B9;
      font-size: 14px;
      overflow: hidden;
    }
    .form-style-7 input[type="text"],
    .form-style-7 input[type="date"],
    .form-style-7 input[type="datetime"],
    .form-style-7 input[type="email"],
    .form-style-7 input[type="number"],
    .form-style-7 input[type="search"],
    .form-style-7 input[type="time"],
    .form-style-7 input[type="url"],
    .form-style-7 input[type="password"],
    .form-style-7 textarea,
    .form-style-7 select 
    {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      width: 100%;
      display: block;
      outline: none;
      border: none;
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      padding: 0;
    }
    .form-style-7 input[type="text"]:focus,
    .form-style-7 input[type="date"]:focus,
    .form-style-7 input[type="datetime"]:focus,
    .form-style-7 input[type="email"]:focus,
    .form-style-7 input[type="number"]:focus,
    .form-style-7 input[type="search"]:focus,
    .form-style-7 input[type="time"]:focus,
    .form-style-7 input[type="url"]:focus,
    .form-style-7 input[type="password"]:focus,
    .form-style-7 textarea:focus,
    .form-style-7 select:focus 
    {
    }
    .form-style-7 li > span{
      background: #F3F3F3;
      display: block;
      padding: 3px;
      margin: 0 -9px -9px -9px;
      text-align: center;
      color: #C0C0C0;
      font-size: 11px;
    }
    .form-style-7 textarea{
      resize:none;
    }
    .form-style-7 input[type="submit"],
    .form-style-7 input[type="button"]{
      background: #2471FF;
      border: none;
      padding: 10px 20px 10px 20px;
      border-bottom: 3px solid #5994FF;
      border-radius: 3px;
      color: #D2E2FF;
    }
    .form-style-7 input[type="submit"]:hover,
    .form-style-7 input[type="button"]:hover{
      background: #6B9FFF;
      color:#fff;
    }

</style>
